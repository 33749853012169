// Imports

import React, { Fragment } from "react"
import accredidationStyles from "./accredidation.module.css"

// Accredidation

const Accredidation = ({ilac, pjla}) => {
  const quote = "Ace Analytical Laboratory's rigorous scientific processes supply our clients with timely, accurate and legally defensible testing data."
  return (
      <Fragment>
        <div className={accredidationStyles.container}>
          <h3 className={accredidationStyles.title}>ISO/IEC 17025:2017 Accredited</h3>
          <div className={accredidationStyles.symbols}>
            <img alt="ILAC" src={ilac} className={accredidationStyles.symbol}/>
            <img alt="PJLA" src={pjla} className={accredidationStyles.symbol}/>
          </div>
          <p className={accredidationStyles.details}>{quote}</p>
        </div>
      </Fragment>
    )
}

export default Accredidation
