// Imports

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { FaArrowRight } from "react-icons/fa"

import { ContactInfoHook } from "../../hooks/contact-information"
import questionStyles from "./question.module.css"

// Header

const Question = ({ title }) => {

  const contactData = ContactInfoHook()

  return (
  <div className={questionStyles.container}>
    <div className={questionStyles.widthContainer}>
     <div className={questionStyles.container__details}>
        <h3 className={questionStyles.question}>{title}</h3> 
        <Link to="/contact/" className={questionStyles.link}>Contact Us <FaArrowRight /></Link> 
     </div>
     <div className={questionStyles.contacts}>
        <a href={"tel:" + contactData.phone} className={questionStyles.contact}>
          <span className={questionStyles.contact__span}>Phone: </span>
          {contactData.phone}
        </a>
        <a href={"mailto:" + contactData.email} target="_blank" rel="noopener noreferrer" className={questionStyles.contact}>
          <span className={questionStyles.contact__span}>Email: </span>
          {contactData.email}
        </a>
     </div>
    </div>
  </div>
  )
}

Question.propTypes = {
  title: PropTypes.string,
}

Question.defaultProps = {
  title: "Have a question for Ace Analytical?",
}

export default Question
