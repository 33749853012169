// Imports

import React from "react"
import PropTypes from "prop-types"

import partnersStyles from "./partners.module.css"
import Partner from "../../components/item-partner/item-partner"

// Header

const Partners = ({ partners, title, subtitle }) => {
  return(
    <div className={partnersStyles.container}>
      <div className={partnersStyles.widthContainer}>
        <h3 className={partnersStyles.title}>{title}</h3>
        <p className={partnersStyles.details}>{subtitle}</p>
        <div className={partnersStyles.partners}>
          {partners.map(partner => {
            return <Partner partner={partner.node} tag={partner.node.id} key={partner.node.id} />
          })}
        </div>
      </div>
    </div>
  )
}

Partners.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

Partners.defaultProps = {
  title: "",
  description: "",
}

export default Partners
