// Imports

import React from "react"

import { Link } from "gatsby"
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Layout from "../components/layout"
import { FaArrowRight } from "react-icons/fa"

import { PartnersHook } from '../hooks/partners'
import { ServicesHook } from '../hooks/services'
import Accredidation from "../components/accredidation/accredidation"
import Emerald from "../components/emerald/emerald"
import Partners from "../components/partners/partners"

import Question from "../components/question/question"
import indexPageStyles from "../styles/pages/index.module.css"
import ServiceCard from "../components/card-service/card-service"

// Index

const IndexPage = ({ data }) => { 
  
  // Data
  const services = ServicesHook().sort((a,b) => a.node.childMarkdownRemark.frontmatter.order - b.node.childMarkdownRemark.frontmatter.order);
  const ourPartners = PartnersHook().sort((a,b) => a.node.childMarkdownRemark.frontmatter.order - b.node.childMarkdownRemark.frontmatter.order);
  const pageData = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  
  // Render
  return (
    <Layout>
      <SEO title="ACE | Nevada's Leading Cannabis Testing Lab" />
      <div className={indexPageStyles.hero}>
        <div className={indexPageStyles.callToAction}>
          <h3 className={indexPageStyles.title}>{pageData.heroTitle}</h3>
          <h3 className={indexPageStyles.subtitle}>{pageData.heroSubtitle}</h3>
          <p className={indexPageStyles.details}>{pageData.heroDetail}</p>
        </div>
      </div>
      <div className={indexPageStyles.servicesCards}>
        <div className={indexPageStyles.servicesCardsContainer}>
          <div className={indexPageStyles.servicesCardsSubContainer}>
            {services.map(service => (
              <ServiceCard service={service.node} newPage={true} tag={service.node.id} key={service.node.id} />
            ))}
          </div>
        </div>
      </div>
      <Accredidation ilac={pageData.accredidationImage1} pjla={pageData.accredidationImage2} />
      <Question />
      <Partners partners={ourPartners} title={pageData.partnersTitle} subtitle={pageData.partnersSubtitle} />
      <Emerald 
        one={pageData.emeraldOne} 
        two={pageData.emeraldTwo} 
        three={pageData.emeraldThree} 
        four={pageData.emeraldFour} 
        five={pageData.emeraldFive} 
        six={pageData.emeraldSix} 
        seven={pageData.emeraldSeven} 
        eight={pageData.emeraldEight} 
        nine={pageData.emeraldNine} 
        ten={pageData.emeraldTen} 
        eleven={pageData.emeraldEleven} 
        twelve={pageData.emeraldTwelve} 
        thirteen={pageData.emeraldThirteen} 
        fourteen={pageData.emeraldFourteen} 
        fifteen={pageData.emeraldFifteen} 
        sixteen={pageData.emeraldSixteen} 
        seventeen={pageData.emeraldSeventeen} 
        eighteen={pageData.emeraldEighteen} 
        nineteen={pageData.emeraldNineteen} 
        twenty={pageData.emeraldTwenty} 
        twentyOne={pageData.emeraldTwentyOne} 
        twentyTwo={pageData.emeraldTwentyTwo} 
      />
      <div className={indexPageStyles.medicalForms}>
        <div className={indexPageStyles.widthContainer}>
          <div className={indexPageStyles.medicalForms__left}>
            <img src={pageData.formsImage} alt="Forms" className={indexPageStyles.medicalForms__img} />
          </div>
          <div className={indexPageStyles.medicalForms__right}>
            <h3 className={indexPageStyles.medicalForms__title}>{pageData.formsTitle}</h3>
            <p className={indexPageStyles.medicalForms__details}>{pageData.formsSubtitle}</p>
            <Link to="/research/" className={indexPageStyles.medicalForms__link}>View All Articles <FaArrowRight /></Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "index-page"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              heroTitle
              heroSubtitle
              heroDetail
              heroButtonTitle
              partnersTitle
              partnersSubtitle
              formsTitle
              formsSubtitle
              formsImage
              accredidationImage1
              accredidationImage2
              emeraldOne
              emeraldTwo
              emeraldThree
              emeraldFour
              emeraldFive
              emeraldSix
              emeraldSeven
              emeraldEight
              emeraldNine
              emeraldTen
              emeraldEleven
              emeraldTwelve
              emeraldThirteen
              emeraldFourteen
              emeraldFifteen
              emeraldSixteen
              emeraldSeventeen
              emeraldEighteen
              emeraldNineteen
              emeraldTwenty
              emeraldTwentyOne
              emeraldTwentyTwo
          }
        }
      }
    }
  }
}`