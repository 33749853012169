// Imports

import React from "react"

import partnerStyles from "./item-partner.module.css"

// Header

const Partner = ({ partner }) => {
  const thePartner = partner.childMarkdownRemark.frontmatter
  if (thePartner.link) {
    return (
      <a className={partnerStyles.partnerLink} href={thePartner.link}>
        <div className={partnerStyles.container}>
          <img className={partnerStyles.img} src={thePartner.image} alt="Partner" />
          <h3 className={partnerStyles.title}>{thePartner.name}</h3>
        </div>
      </a>
    )
  } else {
    return (
      <div className={partnerStyles.container}>
          <img className={partnerStyles.img} src={thePartner.image} alt="Partner" />
          <h3 className={partnerStyles.title}>{thePartner.name}</h3>
      </div>
    )
  }
}

export default Partner
