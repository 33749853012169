// Imports

import { useStaticQuery, graphql } from "gatsby"

// Hook

export const PartnersHook = () => {
  const query = useStaticQuery(
    graphql`
      query partnersQuery {
        allFile(filter: {sourceInstanceName: {eq: "content"}, relativeDirectory: {eq: "partners"}}) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                name
                image
                link
                order
              }
            }
          }
        }
      }
    }
    `
  )
  return query.allFile.edges
}