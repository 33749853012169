// Imports

import React, { Fragment } from "react"
import emeraldStyles from "./emerald.module.css"

// Emerald

const Emerald = ({
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirteen,
  fourteen,
  fifteen,
  sixteen,
  seventeen,
  eighteen,
  nineteen,
  twenty,
  twentyOne,
  twentyTwo,
}) => {
  const quote = "Ace Analytical Laboratory is committed to consumer safety and achieving accuracy, consistency, and excellence in its testing. Ace participates in 'The Emerald Test' ILC/PT and has been awarded the above proficiency test badges."
  return (
      <Fragment>
        <div className={emeraldStyles.container}>
          <a href="https://pt.emeraldscientific.com" className={emeraldStyles.title}>Emerald Badges</a>
          <div className={emeraldStyles.symbols}>
            <img alt="one" src={one} className={emeraldStyles.symbol}/>
            <img alt="two" src={two} className={emeraldStyles.symbol}/>
            <img alt="three" src={three} className={emeraldStyles.symbol}/>
            <img alt="four" src={four} className={emeraldStyles.symbol}/>
            <img alt="five" src={five} className={emeraldStyles.symbol}/>
            <img alt="six" src={six} className={emeraldStyles.symbol}/>
            <img alt="seven" src={seven} className={emeraldStyles.symbol}/>
            <img alt="eight" src={eight} className={emeraldStyles.symbol}/>
            <img alt="nine" src={nine} className={emeraldStyles.symbol}/>
            <img alt="ten" src={ten} className={emeraldStyles.symbol}/>
            <img alt="eleven" src={eleven} className={emeraldStyles.symbol}/>
            <img alt="twelve" src={twelve} className={emeraldStyles.symbol}/>
            <img alt="thirteen" src={thirteen} className={emeraldStyles.symbol}/>
            <img alt="fourteen" src={fourteen} className={emeraldStyles.symbol}/>
            <img alt="fifteen" src={fifteen} className={emeraldStyles.symbol}/>
            <img alt="sixteen" src={sixteen} className={emeraldStyles.symbol}/>
            <img alt="seventeen" src={seventeen} className={emeraldStyles.symbol}/>
            <img alt="eighteen" src={eighteen} className={emeraldStyles.symbol}/>
            <img alt="nineteen" src={nineteen} className={emeraldStyles.symbol}/>
            <img alt="twenty" src={twenty} className={emeraldStyles.symbol}/>
            <img alt="twentyOne" src={twentyOne} className={emeraldStyles.symbol}/>
            <img alt="twentyTwo" src={twentyTwo} className={emeraldStyles.symbol}/>
          </div>
          <p className={emeraldStyles.details}>{quote}</p>
        </div>
      </Fragment>
    )
}

export default Emerald
